function f_resa_options() {
  $('#options input[type=checkbox]').on('change', function () {
    checkbox($(this));
    request();
  });

  $('#options .quantite select').on('change', function () {
    $(this).parents('.item').find('input[type=checkbox]').prop('checked', $(this).val() !== '0');
    request();
  });

  $('#options .quantite').on('click', function () {
    if ($(this).hasClass('disabled')) {
      $(this).parent().find('label').trigger('click');
      request();
    }
  });

  // Initialisation
  request();
  $('#options input[type=checkbox]').each(function () {
    checkbox($(this));
  });

  function checkbox(self) {
    var $quantite = self.parent().parent().find('.quantite');

    if (self.is(":checked")) {
      $quantite.removeClass('disabled');
      $quantite.find('select').val(1).change();
    } else {
      $quantite.addClass('disabled');
      $quantite.find('select').val(0).change();
    }
  }

  function request() {
    $.ajax({
      method: "POST",
      url: $("#options").data('ajax-url'),
      data: $("#devis").serialize(),
      success: function(data) {
        $("._aside").html(data.aside);
        $("#_total").html(data.total);
      }
    });
  }

}

if ($('#options').length > 0) {
  f_resa_options();
}
